.progress-circle__container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.progress-circle__spinner {
  width: 128px !important;
  height: 128px !important;
  margin-top: 100px !important;
}
