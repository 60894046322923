.filter__capital {
  text-transform: capitalize;
}

.filter__expander {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4ab6ad !important;
  color: #fff !important;
}
