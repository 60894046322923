.sidebar__container {
  display: flex;
  flex-direction: column;
}

.menu__excel-export-btn {
  background-color: #f1f3f5 !important;
}

.menu__filter-head {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
}
