.insights-background {
  background-color: #0f003d;
}

.br-bottom {
  border-bottom: #999999 1px solid;
}

.bullet {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-left: 15px;
}
.item-text {
  display: inline;
  vertical-align: middle;
}


.wrapper {
  position: relative;
  border-radius: 5px;

}

.left-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.content {
  padding-left: 10px;
  border-radius: 5px;
}