.close-btn {
  color: #999;
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.3rem 0.7rem;
}

.close-btn:hover {
  background-color: #edf2f7;
  transition: background-color 150ms linear;
}
