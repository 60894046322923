* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  overflow-y: scroll;
  display: flex;
}

body {
  flex: 1;
  margin: 0;
  box-sizing: border-box;
}

#root,
#root > div {
  width: 100%;
  height: 100%;
}

.container-border {
  border: 1px solid #d8d8d8;
}

