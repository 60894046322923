.app__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app__content {
  flex: 1;
  display: flex;
  min-height: 0;
}
