.spinner {
    display: block;
    visibility: visible;
    opacity: 1;
    margin: 0 auto;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 3px solid #180046;
    border-top-color: #9f21c5;
    animation: spinner 1s ease-in-out infinite;
    transition: border-top-color 2s ease;
    transition: border-color 2s ease;
    margin: 0.22rem auto;
}

.spinner-muted {
    border: 3px solid #f5f5f5;
    border-top-color: #f2706d;
}

.spinner-lg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border-width: 5px;
}


@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}




.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}

    .animated.tease {
        -webkit-animation-duration: 0.5s;
        -moz-animation-duration: 0.5s;
        -ms-animation-duration: 0.5s;
        -o-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }

    .animated.hinge {
        -webkit-animation-duration: 2s;
        -moz-animation-duration: 2s;
        -ms-animation-duration: 2s;
        -o-animation-duration: 2s;
        animation-duration: 2s;
    }

@-webkit-keyframes bounceIn {

    0% {
        opacity: 0;
        -webkit-transform: scale(0.8);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes bounceIn {

    0% {
        opacity: 0;
        -moz-transform: scale(0.8);
    }

    50% {
        opacity: 1;
        -moz-transform: scale(1.05);
    }

    100% {
        opacity: 1;
        -moz-transform: scale(1);
    }
}

@-o-keyframes bounceIn {

    0% {
        opacity: 0;
        -o-transform: scale(0.8);
    }

    50% {
        opacity: 1;
        -o-transform: scale(1.05);
    }

    100% {
        opacity: 1;
        -o-transform: scale(1);
    }
}

@keyframes bounceIn {

    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    -moz-animation-name: bounceIn;
    -o-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.delay {
    -webkit-animation-delay: 0.30s;
    -moz-animation-delay: 0.30s;
    -o-animation-delay: 0.30s;
    animation-delay: 0.30s;
}

.delay-one {
    -webkit-animation-delay: 0.60s;
    -moz-animation-delay: 0.60s;
    -o-animation-delay: 0.60s;
    animation-delay: 0.60s;
}

.delay-two {
    -webkit-animation-delay: 1.0s;
    -moz-animation-delay: 1.0s;
    -o-animation-delay: 1.0s;
    animation-delay: 1.0s;
}

.delay-three {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    -moz-animation-name: fadeInDown;
    -o-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}



@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
}






.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    -moz-animation-name: fadeOutDown;
    -o-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
}

@-moz-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }
}

@-o-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -o-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animated.fadeOut {
    -webkit-animation-name: fadeOut;
    -moz-animation-name: fadeOut;
    -o-animation-name: fadeOut;
    animation-name: fadeOut;
}

.animated.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    -moz-animation-name: fadeOutLeft;
    -o-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}








.pulse {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    margin-left: -36px;
    width: 22px;
    height: 22px;
    line-height: 72px;
    font-family: sans-serif;
    font-weight: 700;
    font-size: 34px;
    text-align: center;
    color: #fff;
}

    .pulse span,
    .pulse:before,
    .pulse:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0 solid #65c0de;
        border-radius: 100%;
        -webkit-animation: da-pulse 2s ease-in-out 0s infinite;
        animation: da-pulse 2s ease-in-out 0s infinite;
    }

    .pulse span {
        border-color: #65c0de;
        border-width: 2px;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
    }

    .pulse:before {
        border-color: #99ddf3;
        border-width: 2px;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
    }

    .pulse:after {
        border-color: #a8e5f9;
        border-width: 1px;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
    }

@-webkit-keyframes da-pulse {
    0%,
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        opacity: 0;
    }
    48%,
    52% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes da-pulse {
    0%,
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        opacity: 0;
    }
    48%,
    52% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}




.wave {
    -webkit-animation: moveTheWave 4800ms linear infinite;
    animation: moveTheWave 4800ms linear infinite;
    stroke-dasharray: 0 16 101 16;
}

@-webkit-keyframes moveTheWave {

    0% {
        stroke-dashoffset: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        stroke-dashoffset: -133;
        -webkit-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

}

@keyframes moveTheWave {

    0% {
        stroke-dashoffset: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        stroke-dashoffset: -133;
        -webkit-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

}

